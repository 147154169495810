<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <b-link class="brand-logo">
        <logo
          :height="`50px`"
          :full="true"
        />
      </b-link>

      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            :src="imgUrl"
            fluid
            :alt="$t('Imagem')"
          />
        </div>
      </b-col>

      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{ $t('Insira a nova password?') }}
          </b-card-title>

          <!-- form -->
          <validation-observer
            ref="changePasswordForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-reset-password-form mt-2"
              method="POST"
              @submit.prevent="changePassword"
            >

              <b-form-group
                :label="$t('Nova password')"
                label-for="reset-password-new"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('password')"
                  vid="Password"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-new"
                      v-model="newPassword"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="reset-password-new"
                      placeholder=""
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label-for="reset-password-confirm"
                :label="$t('Confirmar nova password')"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('confirmar password')"
                  rules="required|confirmed:Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-confirm"
                      v-model="newPasswordConfirm"
                      :type="password2FieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false:null"
                      name="reset-password-confirm"
                      placeholder=""
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid || loadingSubmit"
              >
                <b-spinner
                  v-if="loadingSubmit===true"
                  small
                />
                <span v-if="loadingSubmit===true">&nbsp;{{ $t('Aguarde...') }}.</span>
                <span v-if="loadingSubmit===false">&nbsp;{{ $t('Alterar password') }}</span>
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" /> {{ $t('Voltar') }}
            </b-link>
          </p>
        </b-col>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import logo from '@core-custom/layouts/components/Logo.vue'
import {
  BRow, BCol, BCardTitle, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton, BImg, BSpinner,
} from 'bootstrap-vue'
import { required } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VeeValidateLoadLocale } from '@core-custom/mixins/geral'
import { useRouter } from '@core/utils/utils'

export default {
  components: {
    logo,
    BRow,
    BCol,
    BButton,
    BCardTitle,
    BForm,
    BFormGroup,
    BImg,
    BInputGroup,
    BLink,
    BFormInput,
    BSpinner,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [VeeValidateLoadLocale],
  data() {
    return {
      newPassword: '',
      newPasswordConfirm: '',
      sideImg: require('@/assets/images/pages/reset-password-v2.svg'),

      required,

      password1FieldType: 'password',
      password2FieldType: 'password',

      loadingSubmit: false,
      swID: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/reset-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const self = vm

      if ((self.paramsUrl !== null) && (self.paramsUrl !== undefined) && ('token' in self.paramsUrl) && (self.paramsUrl.token !== '')) {
        vm.$store
          .dispatch('auth/getChangePasswordValid', { token: self.paramsUrl.token })
          .then(response => {
            if (response !== '') {
              self.swID = response
            } else {
              next({ name: 'auth-login' })
            }
          }).catch(() => {
            next({ name: 'auth-login' })
          })
      } else {
        next({ name: 'auth-login' })
      }
    })
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    async changePassword() {
      this.loadingSubmit = true

      this.$refs.changePasswordForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('password', this.newPasswordConfirm)
          formData.append('swID', this.swID)

          this.$store
            .dispatch('auth/changeForgotPasswordRequest', formData)
            .then(response => {
              if (response.msg !== '') {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: this.$t('Alteração da password'),
                    icon: 'CheckIcon',
                    variant: 'success',
                    text: response.msg,
                  },
                })
              }

              this.loadingSubmit = false
            })
            .catch(responseError => {
              if ((responseError.error === 1) && (responseError.msg !== '')) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: this.$t('Alerta'),
                    icon: 'CheckIcon',
                    variant: 'danger',
                    text: responseError.msg,
                  },
                })
              }
              this.loadingSubmit = false
            })
        } else {
          this.loadingSubmit = false
        }
      })
    },
  },
  setup() {
    const { route } = useRouter()
    const paramsUrl = route.value.params

    return {
      paramsUrl,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
